import React from 'react';
import './About.css';

const About = () => (
    <div className="about">
        <div className="about-header">
            <h1 className="headline">About Us</h1>
            <p className="subtitle">Reclaim Your Body, Rewrite Your Story</p>
        </div>
        <div className="about-content">
            <div className="about-box">
                <h2 className="box-title">Why Choose Us?</h2>
                <p>
                    <strong>Advanced Technology for Exceptional Results</strong><br />
                    Using cutting-edge equipment, we specialize in removing tattoos that others find too challenging. From vibrant inks to deep, dark tattoos, we achieve unparalleled results.
                </p>
                <p>
                    <strong>Compassionate Mission with Community Impact</strong><br />
                    Through special grants, we offer gang tattoo removal to help individuals embrace a brighter future.
                </p>
                <p>
                    <strong>Tailored Care for Every Client</strong><br />
                    Every tattoo and skin type is unique. Our personalized treatment plans ensure safety, comfort, and efficiency.
                </p>
            </div>
            <div className="about-box">
                <h2 className="box-title">What We Offer</h2>
                <ul className="offer-list">
                    <li>Dark Tattoo Removal: Even the most stubborn tattoos are no match for us.</li>
                    <li>Gang Tattoo Removal: Helping individuals take a positive step forward.</li>
                    <li>Cosmetic Blemish Correction: Helping you love the skin you’re in.</li>
                    <li>State-of-the-Art Laser Treatments: Faster, safer, and more effective results.</li>
                </ul>
            </div>
            <div className="about-box">
                <h2 className="box-title">Our Promise</h2>
                <p>
                    At Second Chance Cosmetics, we’re not just removing tattoos or blemishes – we’re helping you tell a new story, one you can wear with pride. Your safety and satisfaction are our top priorities.
                </p>
            </div>
        </div>
    </div>
);

export default About;
