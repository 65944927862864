import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import bannerImage from './secondchancebanner.png'; // Adjust the path if needed

const Header = () => (
    <header className="header">
        <img src={bannerImage} alt="Second Chance Cosmetics" className="header-banner" />
        <nav>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/services">Services</Link>
            <Link to="/contact">Contact</Link>
        </nav>
    </header>
);

export default Header;
