import React from 'react';
import './Home.css';
import secondChanceImage from './secondchance.png'; // Adjust path as necessary

const Home = () => (
    <div className="home">
        <img src={secondChanceImage} alt="Second Chance Cosmetics" className="home-image" />
        <p>Reclaim your skin with our expert laser tattoo removal services.</p>
    </div>
);

export default Home;
