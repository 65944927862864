import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        emailjs
            .send(
                'service_d3zoefu', // Replace with your EmailJS service ID
                'template_1c0otgv', // Replace with your EmailJS template ID
                formData,
                'SuEu82TgIT-qRKhRI' // Replace with your EmailJS user ID
            )
            .then(() => {
                setSuccessMessage('Your message has been sent successfully!');
                setFormData({ name: '', email: '', message: '' }); // Reset form
            })
            .catch(() => {
                setErrorMessage('An error occurred. Please try again.');
            });
    };

    return (
        <div className="contact">
            <h2>Contact Us</h2>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
                <label>
                    Name:
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Message:
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </label>
                <button type="submit">Send</button>
            </form>
        </div>
    );
};

export default Contact;
