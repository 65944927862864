import React from 'react';
import './Services.css';
import removal1 from './removal1.png';
import removal2 from './removal2.png';
import removal3 from './removal3.png';

const Services = () => (
    <div className="services">
        <h2>Our Services</h2>
        <p>We specialize in advanced tattoo and cosmetic blemish removal. Explore our services through the images below:</p>
        <div className="image-grid">
            <div className="image-item">
                <img src={removal1} alt="Tattoo Removal Example 1" />
            </div>
            <div className="image-item">
                <img src={removal2} alt="Tattoo Removal Example 2" />
            </div>
            <div className="image-item">
                <img src={removal3} alt="Tattoo Removal Example 3" />
            </div>
        </div>
        <div className="expectations-section">
            <h3 className="expectations-title">Manage Expectations</h3>
            <p className="expectations-text">
                Removing tattoos can be a complex process, and results vary depending on several factors. 
                <strong> Darker tattoos</strong> often require up to <strong>10 sessions</strong> due to the depth of the ink and its resistance to laser treatments. 
                Conversely, tattoos that were poorly done may take fewer sessions since the ink is often not deeply embedded.
            </p>
            <p className="expectations-text">
                Factors such as the <strong>layer of skin</strong> where the ink resides, the <strong>ink's composite material</strong>, and whether any additional substances were mixed with the ink can significantly impact treatment time. These variables can create a tattoo that is more resistant to laser treatment. 
                At Second Chance Cosmetics, we prioritize transparency and will work with you to develop a tailored plan to achieve the best results possible.
            </p>
        </div>
    </div>
);

export default Services;